<template>
  <div style="text-align: justify">
    <p class="mb-6">
      This pre-lab is an example of a typical calculation for determining the stoichiometry of a
      hydrate. Using the data below, determine the stoichiometry of the hydrated calcium sulfate,
      <chemical-latex content="CaSO4 * x H2O" />
    </p>

    <p class="mb-6">
      <span class="text-bold">Please Note:</span> Although <stemble-latex content="$x$" /> will
      normally be an integer, in this theoretical calculation, you should report the value of
      <stemble-latex content="$x$" />
      to the correct number of significant figures. Please see the Rules for Significant Figures in
      your lab manuals for more information regarding sig figs.
    </p>

    <p class="mb-1">
      <u>Data:</u>
    </p>

    <v-simple-table style="margin-left: 15px; margin-bottom: 20px">
      <tbody>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Mass of crucible }$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="crucible" unit="\text{g}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Mass of crucible + gypsum}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <latex-number :number="crucibleGypsum" />
            <stemble-latex content="$\text{g}$" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Mass of crucible + contents after heating}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$=\,\,\,$" />
            <latex-number :number="crucibleCalciumSulfate" />
            <stemble-latex content="$\text{g}$" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'UPEIGypsumPreLab',
  components: {
    ChemicalLatex,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    crucible() {
      return this.taskState.getValueBySymbol('crucible').content;
    },
    calciumSulfate() {
      return this.taskState.getValueBySymbol('calciumSulfate').content;
    },
    xValue() {
      return this.taskState.getValueBySymbol('xValue').content;
    },
    crucibleCalciumSulfate() {
      return (this.crucible.toFloat() + this.calciumSulfate.toFloat() / 3).toFixed(4);
    }, // Note that calciumSulfate value was scaled by factor of 3
    crucibleGypsum() {
      return (
        this.crucible.toFloat() +
        this.calciumSulfate.toFloat() / 3 +
        (((this.calciumSulfate.toFloat() / 3 / 136.15) * this.xValue.toFloat()) / 2) * 18.01528
      ).toFixed(4);
    }, // Note that xValue was scaled by a factor of 2
  },
};
</script>
